.app-content {
  padding: $app-content-padding-y $app-content-padding-x;
  flex: 1;

  @if $enable-rtl {
    margin-right: $app-sidebar-width;
  } @else {
    margin-left: $app-sidebar-width;
  }
  @include media-breakpoint-down(md) {
    padding: $app-content-sm-padding-y $app-content-sm-padding-x;

    @if $enable-rtl {
      margin-right: 0;
    } @else {
      margin-left: 0;
    }
  }

  & .app-content-inner-padding {
    padding: $app-content-padding-y $app-content-padding-x;

    @include media-breakpoint-down(md) {
      padding: $app-content-sm-padding-y $app-content-sm-padding-x;
    }
  }
  & .app-content-inner-padding-x {
    padding-left: $app-content-padding-x;
    padding-right: $app-content-padding-x;

    @include media-breakpoint-down(md) {
      padding-left: $app-content-sm-padding-x;
      padding-right: $app-content-sm-padding-x;
    }
  }
  & .app-content-inner-padding-y {
    padding-top: $app-content-padding-y;
    padding-bottom: $app-content-padding-y;

    @include media-breakpoint-down(md) {
      padding-top: $app-content-sm-padding-y;
      padding-bottom: $app-content-sm-padding-y;
    }
  }
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .loading-bar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-top-color: var(--bs-theme);
    animation: spin 1s ease-in-out infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
