.offcanvas-end {
  opacity: 0;
}

.offcanvas-end.show {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.offcanvas-container {
  width: 60% !important;
}

@media (max-width: 767px) {
  .offcanvas-container {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .offcanvas-container {
    width: 40% !important;
  }
}

.offcanvas-container .separator {
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
  text-transform: capitalize;
}
