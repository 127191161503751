.app {
  padding-top: $app-header-height;
}
@keyframes click-animation {
  0% {
    color: black;
  }
  50% {
    color: red;
  }
  100% {
    color: black;
  }
}

.click-animation {
  animation: click-animation 0.5s;
}
