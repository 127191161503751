.remediation-wrapper {
  background-color: rgb(
    42,
    49,
    58,
    0.6
  ); /* Dark grey background, close to your app's background */
  border: 1px solid #404952; /* Slightly lighter border */
  border-radius: 5px; /* Rounded corners */
  padding: 15px; /* Inner padding */
  margin: 10px 0; /* Margin top and bottom */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-family: Arial, sans-serif; /* Font family */
  color: #ffffff; /* White text color */
}

.remediation-wrapper .p-1 {
  margin: 0; /* Removing default paragraph margin */
}

.remediation-wrapper strong {
  font-weight: 600; /* Bold font for labels */
  opacity: 0.9; /* Slightly faded for contrast */
}

.table-responsive table {
  text-align: left;
  /* text-wrap */
  word-break: break-word;
}
